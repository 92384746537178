import React from 'react';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { Provider } from 'next-auth/client';

import { isDev } from '@/utils/config/environment';
import { AuthProvider } from '@/components';

import '@/styles/styles.scss';

export const reportWebVitals = (metric: NextWebVitalsMetric) => {
	if (isDev) {
		console.log('Web Vitals', metric);
	}
};

export const App = ({ Component, pageProps }: AppProps) => (
	<Provider session={pageProps.session}>
		<AuthProvider>
			<Component {...pageProps} />
		</AuthProvider>
	</Provider>
);

export default App;
